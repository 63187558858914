import React, { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import {
  LAYOUT_COLOR_DARK,
  LEFT_SIDEBAR_THEME_DARK,
} from '../constants/layout';
import { changeBodyAttribute } from '../utils/layout';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""></div>;

export default function VerticalLayout() {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  // body attributes define theme
  useEffect(() => {
    changeBodyAttribute('data-leftbar-theme', LEFT_SIDEBAR_THEME_DARK);
    changeBodyAttribute('data-layout-color', LAYOUT_COLOR_DARK);
  }, []);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened((prevState) => {
      setIsMenuOpened(!prevState);
    });

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove('sidebar-enable');
      } else {
        document.body.classList.add('sidebar-enable');
      }
    }
  };

  const isCondensed = false;
  const isLight = false;

  return (
    <div className="wrapper">
      <Suspense fallback={loading()}>
        <LeftSidebar
          isCondensed={isCondensed}
          isLight={isLight}
          hideUserProfile={true}
        />
      </Suspense>
      <div className="content-page">
        <div className="content">
          <Suspense fallback={loading()}>
            <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
          </Suspense>
          <Container fluid>
            <Suspense fallback={loading()}>
              <Outlet />
            </Suspense>
          </Container>
        </div>

        <Suspense fallback={loading()}>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
}
